import { Popover, PopoverPanel, PopoverButton } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import {
    AcademicCapIcon,
    EnvelopeIcon,
    GlobeAmericasIcon,
    InformationCircleIcon,
    MagnifyingGlassIcon,
    MegaphoneIcon,
    NewspaperIcon,
    RectangleGroupIcon,
    SparklesIcon,
    UserGroupIcon,
    UsersIcon,
    AdjustmentsHorizontalIcon,
    ArrowRightOnRectangleIcon,
    ChatBubbleBottomCenterTextIcon,
} from "@heroicons/react/24/outline";
import { ConditionalLink } from "./mobileNavTopItem";
import { NavPageInterface } from "./nav";

export const getIcon = (icon?: string) => {
    switch (icon) {
        case "academic-cap":
            return AcademicCapIcon;
        case "envelope":
            return EnvelopeIcon;
        case "globe-americas":
            return GlobeAmericasIcon;
        case "information-circle":
            return InformationCircleIcon;
        case "magnifying-glass":
            return MagnifyingGlassIcon;
        case "megaphone":
            return MegaphoneIcon;
        case "newspaper":
            return NewspaperIcon;
        case "rectangle-group":
            return RectangleGroupIcon;
        case "sparkles":
            return SparklesIcon;
        case "user-group":
            return UserGroupIcon;
        case "users":
            return UsersIcon;
        case "adjustments-horizontal":
            return AdjustmentsHorizontalIcon;
        case "chat-bubble-bottom-center-text":
            return ChatBubbleBottomCenterTextIcon;
        case "arrow-right-on-rectangle":
            return ArrowRightOnRectangleIcon;
        default:
            return RectangleGroupIcon;
    }
};

const LinkContents = ({ item }: { item: NavPageInterface["page"] }) => {
    const Icon = getIcon(item?.icon);

    return (
        <>
            <div className="w-16 h-16 min-w-16 min-h-16 shrink-0 bg-black flex items-center justify-center rounded-xl">
                <Icon className="h-6 w-6 text-white" />
            </div>
            <div>
                <p className="text-base font-medium text-gray-900">
                    {item.title}
                </p>
                <p className="mt-1 text-sm text-gray-500">
                    {item?.description}
                </p>
            </div>
        </>
    );
};

export default function HeaderDropdown({
    items,
    title,
}: {
    items: NavPageInterface[];
    title: string;
}) {
    return (
        <Popover>
            {({ open }) => (
                <>
                    <PopoverButton className="flex items-center gap-x-1 font-medium leading-6 text-gray-900 p-2">
                        {title}
                        {open ? (
                            <ChevronUpIcon
                                className="h-5 w-5 flex-none text-gray-400"
                                aria-hidden="true"
                            />
                        ) : (
                            <ChevronDownIcon
                                className="h-5 w-5 flex-none text-gray-400"
                                aria-hidden="true"
                            />
                        )}
                    </PopoverButton>

                    <PopoverPanel
                        transition
                        anchor="bottom"
                        className="z-50 bg-gray-50 shadow-lg ring-1 ring-gray-900/5 ml-0! mt-6 w-full"
                    >
                        <div className="mx-auto grid max-w-7xl grid-cols-3 gap-x-4 px-6 py-10 lg:px-8 xl:gap-x-8 gap-y-6">
                            {items.map((item) => (
                                <ConditionalLink
                                    inertia={item?.page?.inertia}
                                    href={item.page.url}
                                    className="-m-3 flex rounded-md p-3 hover:bg-gray-100 gap-x-4"
                                    key={item.page.url}
                                >
                                    <LinkContents item={item.page} />
                                </ConditionalLink>
                            ))}
                        </div>
                    </PopoverPanel>
                </>
            )}
        </Popover>
    );
}
